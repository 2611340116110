@import 'styles/variables.scss';

.dynamics-form {
  &__markup {
    width: 100%;
    position: relative;
    form {
      .lp-form-button,
      .lp-form-fieldInput {
      }

      .lp-ellipsis,
      .lp-required {
        display: none;
      }

      .columnContainer {
        padding: 0 !important;
      }

      .lp-form-fieldInput:not(.lp-form-button) {
        border: 1px solid #383937;
        border-radius: 3px;
        padding: 12px 16px;
        outline: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: -0.03px;
        margin-bottom: 8px;
        width: calc(100% - 34px) !important;

        &::placeholder {
          text-indent: 0px;
          transition: text-indent 0.3s ease;
        }

        &:focus {
          outline: none;

          &::placeholder {
            text-indent: 500px;
            transition: text-indent 0.3s ease;
          }
        }
      }

      .lp-form-button {
        font-family: Greycliff CF;
        padding: 8px 16px;
        background: $blackFont;
        border-radius: 4px;
        color: $white;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        border: none;
        outline: none;
        min-height: 40px;
        max-width: 100%;
        width: 100%;
        font-weight: 400;
        cursor: pointer;
        text-decoration: none;
        box-sizing: border-box;
        display: flex;
        justify-content: center;

        &::after {
          display: block;
          content: '';
          width: 14px;
          height: 8px;
          transform: rotate(-90deg);
          margin-left: 16px;
          margin-top: 8px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url('../images/svg/arrow-down.svg');
        }
      }
    }
  }
}
