@import 'styles/variables.scss';
.filters {
  $block: &;
  display: block;
  width: 100%;

  &__legend {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02px;
    color: $blackFont;
    margin-bottom: 24px;
  }

  &__row {
    width: 100%;
    @media (min-width: $tablet-small) {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: 16px;
    }
    @media (min-width: $desktop) {
      gap: 28px;
    }

    @media (min-width: $desktop-large) {
      gap: 56px;
    }

    & + & {
      margin-top: 24px;
    }

    &--space-evenly {
      #{$block}__group {
        flex: 1;
      }
    }

    &--4, &--3, &--2 {
      #{$block}__group {
        flex-grow: 0;
      }
    }

  }

  &__group {
    flex-grow: 1;
    width: auto;
    & + & {
      margin-top: 16px;
      @media (min-width: $tablet-small) {
        margin-top: 0;
      }
    }

    &--f-auto {
      flex-grow: 0;
      width: auto;
    }
  }

  &__reset {
    display: block;
    margin-bottom: 12px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-decoration-line: underline;
    color: #000000;
    padding-right: 0;
    margin-top: 24px;
    margin-bottom: 24px;

    @media (min-width: $tablet-small) {
      margin-top: 0;
      margin-bottom: 12px;
    }
  }

  &__form {
    position: relative;
  }

  &__close {
    display: none;
  }

  &--modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    #{$block}__form {
      min-width: 90VW;
      background-color: white;
      padding: 20px;
      border-radius: 10px;
      max-width: 90%;
      max-height: 90%;
      overflow: auto;
    }

    #{$block}__close {
      display: inline-block;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
}
