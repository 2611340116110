button {
  font-family: inherit;
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
}

.yellow-button {
  padding: 8px 16px;
  background: $yellow;
  border-radius: 4px;
  color: $blackFont;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  border: none;
  outline: none;
  width: fit-content;
  min-height: 40px;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  justify-content: center;

  & span {
    display: flex;
    align-items: center;
    white-space: nowrap;

    &::after {
      display: block;
      content: '';
      width: 8px;
      height: 14px;
      margin-left: 16px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../../images/svg/black-arrow-button.svg');
    }
  }
}

.ghost-button {
  padding: 8px 16px;
  background: transparent;
  border-radius: 4px;
  color: $blackFont;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border: 1px solid $grey;
  outline: none;
  width: fit-content;
  min-height: 40px;
  text-decoration: underline;
  text-underline-offset: 0.1em;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  justify-content: center;

  & span {
    display: flex;
    align-items: center;
    white-space: nowrap;

    &::after {
      display: block;
      content: '';
      width: 8px;
      height: 14px;
      margin-left: 16px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../../images/svg/black-arrow-button.svg');
    }
  }

  &--active {
    background-color: $yellow;
    font-weight: 700;
    text-decoration: none;
  }
}

.black-button {
  padding: 8px 16px;
  background: $blackFont;
  border-radius: 4px;
  color: $white;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  border: none;
  outline: none;
  min-height: 40px;
  width: fit-content;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  &.--tour {
    & span {
      white-space: nowrap;

      &::after {
        width: 24px;
        height: 24px;
        transform: none;
        margin-left: 11px;
        background-image: url('../../images/svg/icon-tour-button.svg');
      }
    }
  }

  &.--download {
    & span {
      white-space: nowrap;

      &::after {
        width: 24px;
        height: 24px;
        transform: none;
        margin-left: 17px;
        background-image: url('../../images/svg/icon-download-button.svg');
      }
    }
  }

  & span {
    display: flex;
    align-items: center;
    white-space: nowrap;

    &::after {
      display: block;
      content: '';
      width: 14px;
      height: 8px;
      transform: rotate(-90deg);
      margin-left: 16px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../../images/svg/arrow-down.svg');
    }
  }
}

.tab-button {
  padding: 12px;
  background: $grey;
  border-radius: 4px 4px 0 0;
  color: $blackFont;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: none;
  outline: none;
  width: fit-content;
  min-height: 40px;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  justify-content: center;
  white-space: nowrap;

  @media (min-width: $desktop) {
    padding: 12px 24px;
  }

  &--active {
    background: $yellow;
    font-weight: 700;
  }
}

.slider-button-wrap {
  position: absolute;
  width: 100%;
  z-index: 2;
  max-width: 1616px;
  top: 50%;
  left: 50%;
  transform: translate(-50%);

  &.--black {
    & .slider-button-wrap__left,
    & .slider-button-wrap__right {
      position: absolute;
      left: 0;
      background: $black;
      transform: translateY(-50%);

      &::after {
        background-image: url('../../images/svg/yellow-arrow.svg');
      }
    }

    & .slider-button-wrap__right {
      left: unset;
      right: 0;
      transform: translateY(-50%);
    }
  }

  &.--white {
    & .slider-button-wrap__left,
    & .slider-button-wrap__right {
      position: absolute;
      left: 0;
      background: $black;
      transform: translateY(-50%);

      &::after {
        background-image: url('../../images/svg/white-arrow.svg');
      }
    }

    & .slider-button-wrap__right {
      left: unset;
      right: 0;
      transform: translateY(-50%);
    }
  }

  &.--yellow {
    & .slider-button-wrap__left,
    & .slider-button-wrap__right {
      background: $yellow;
      position: absolute;
      left: 0;
      transform: translateY(-50%);

      &::after {
        background-image: url('../../images/svg/black-arrow.svg');
      }
    }

    & .slider-button-wrap__right {
      left: unset;
      right: 0;
      transform: translateY(-50%);
    }
  }

  &__left,
  &__right {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    &::after {
      display: block;
      content: '';
      width: 8px;
      height: 14px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__right {
    &::after {
      transform: rotate(180deg);
    }
  }
}
