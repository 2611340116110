.radio {
  display: flex;
  justify-content: flex-start;
  & > *:not(:last-child) {
    margin-right: 8px;
  }

  &__input {
    border: 1px solid $blackFont;
    border-radius: 3px;
    padding: 12px 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.001em;
    color: $blackFont;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover{
      background-color: $grey;
      border-color: transparent;
    }
  }

  &__input.--active {
    color: $yellow;
    background-color: $blackFont;
  }
}
