.fieldset {
  display: flex;
  flex-direction: column;

  // max-width: 305px;
  // &:not(:last-child) {
  //   display: flex;
  //   flex-direction: column;
  //   margin-right: 40px;

  //   @media (max-width: $tablet) {
  //     margin-bottom: 20px;
  //   }
  // }

  &__label {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.001em;
    color: $blackFont;
    margin-bottom: 8px;
  }

  &--f-grow {
    flex-grow: 1;
    width: auto;
  }
}
