.select-wrap {
  display: flex;
  justify-content: space-between;
  & > *:not(:last-child) {
    margin-right: 16px;
  }
}

.select {
  display: flex;
  width: 146px;
  min-width: 146px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  letter-spacing: -0.001em;
  color: $black;
  border: 1px solid $blackFont;
  border-radius: 3px;
  cursor: pointer;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    width: 8px;
    height: 14px;
    background-image: url(../../images/svg/black-arrow-button.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__options {
    display: none;
    max-height: 248px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 2;
    border: 1px solid $blackFont;
    border-radius: 3px;

    & > * {
      cursor: pointer;
      transition: all 0.3s ease;
    }

    & > *:hover {
      color: $yellow;
      background-color: $blackFont;
    }
  }

  &__option {
    padding: 12px 16px;
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    letter-spacing: -0.001em;
    color: $black;
    text-transform: capitalize;
    &--f-grow {
      flex-grow: 1;
      width: auto;
    }
    @media (min-width: $desktop) {
      // padding: 12px 30px;
    }
  }

  &--f-grow {
    flex-grow: 1;
    width: auto;
  }
}
