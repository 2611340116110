@use "sass:list";
@import 'styles/variables.scss';
@import 'styles/base/buttons.scss';
@import 'styles/base/rich-text.scss';

html {
  background: $white;
  scroll-behavior: smooth;
}
body {
  opacity: 1 !important;
  margin: 0;
  //font-family: 'Acumin Pro', sans-serif;
  font-family: $mainFont, sans-serif;
  //, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.locked {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
  color: $black;
}
h2 {
  @media (max-width: $tablet) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (min-width: $desktop) {
    font-size: 46px;
    line-height: 64px;
  }
}
h3 {
  @media (max-width: $tablet) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (min-width: $desktop) {
    font-size: 24px;
    line-height: 32px;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

sup {
  font-size: 10px;
  transform: translateY(-20%);
  display: inline-block;
}

.container {
  max-width: 1344px;
  margin: 0 auto;
  width: 100%;

  @media (min-width: $desktop) {
    padding: 0 32px;
  }
}

.yellow-vertical-line::after {
  display: block;
  content: '';
  position: absolute;
  width: 1px;
  height: 24px;
  background: $yellow;
  top: 50%;
  transform: translateY(-50%);

  @media (min-width: $desktop) {
    height: 20px;
  }
}

button {
  font-family: inherit;
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
}

.yellow-button {
  padding: 8px 16px;
  background: $yellow;
  border-radius: 4px;
  color: $blackFont;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  border: none;
  outline: none;
  width: fit-content;
  min-height: 40px;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  justify-content: center;

  & span {
    display: flex;
    align-items: center;
    white-space: nowrap;

    &::after {
      display: block;
      content: '';
      width: 8px;
      height: 14px;
      margin-left: 16px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../images/svg/black-arrow-button.svg');
    }
  }
}

.black-button {
  padding: 8px 16px;
  background: $blackFont;
  border-radius: 4px;
  color: $white;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  border: none;
  outline: none;
  min-height: 40px;
  width: fit-content;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  &.--tour {
    & span {
      white-space: nowrap;

      &::after {
        width: 24px;
        height: 24px;
        transform: none;
        margin-left: 11px;
        background-image: url(../images/svg/icon-tour-button.svg);
      }
    }
  }

  &.--download {
    & span {
      white-space: nowrap;

      &::after {
        width: 24px;
        height: 24px;
        transform: none;
        margin-left: 17px;
        background-image: url(../images/svg/icon-download-button.svg);
      }
    }
  }

  & span {
    display: flex;
    align-items: center;
    white-space: nowrap;

    &::after {
      display: block;
      content: '';
      width: 14px;
      height: 8px;
      transform: rotate(-90deg);
      margin-left: 16px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../images/svg/arrow-down.svg');
    }
  }
}

.slider-button-wrap {
  position: absolute;
  width: 100%;
  z-index: 2;
  max-width: 1616px;
  top: 50%;
  left: 50%;
  transform: translate(-50%);

  &.--black {
    & .slider-button-wrap__left,
    & .slider-button-wrap__right {
      position: absolute;
      left: 0;
      background: $black;
      transform: translateY(-50%);

      &::after {
        background-image: url('../images/svg/yellow-arrow.svg');
      }
    }

    & .slider-button-wrap__right {
      left: unset;
      right: 0;
      transform: translateY(-50%);
    }
  }

  &.--white {
    & .slider-button-wrap__left,
    & .slider-button-wrap__right {
      position: absolute;
      left: 0;
      background: $black;
      transform: translateY(-50%);

      &::after {
        background-image: url('../images/svg/white-arrow.svg');
      }
    }

    & .slider-button-wrap__right {
      left: unset;
      right: 0;
      transform: translateY(-50%);
    }
  }

  &.--yellow {
    & .slider-button-wrap__left,
    & .slider-button-wrap__right {
      background: $yellow;
      position: absolute;
      left: 0;
      transform: translateY(-50%);

      &::after {
        background-image: url('../images/svg/black-arrow.svg');
      }
    }

    & .slider-button-wrap__right {
      left: unset;
      right: 0;
      transform: translateY(-50%);
    }
  }

  &__left,
  &__right {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    &::after {
      display: block;
      content: '';
      width: 8px;
      height: 14px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__right {
    &::after {
      transform: rotate(180deg);
    }
  }
}

.yellow-span-top {
  position: relative;

  & strong {
    position: relative;

    &::after {
      display: block;
      content: '';
      width: 100%;
      background: $yellow;
      position: absolute;
      right: 0;
      top: -6px;

      @media (max-width: $tablet) {
        height: 4px;
      }

      @media (min-width: $desktop) {
        height: 8px;
      }
    }
  }
}

.white-link {
  & a {
    color: $white;
  }
}

.white-on-light-shadow {
  text-shadow: 0.05em 0.05em 0.15em fade-out($black, 0.2), 0.02em 0.02em 0.1em fade-out($black, 0.2);
}
.white-on-light-shadow--stronger {
  text-shadow: 0.05em 0.05em 0.15em $black, 0.05em 0.05em 0.15em $black, 0.05em 0.05em 0.1em $black, 0 0 0.1em $black;
  &,
  a {
    font-weight: bold !important;
  }

  [class*='breadcrumbs-back'] a::before {
    filter: drop-shadow(0.05em 0.05em 0.15em $black) drop-shadow(0.02em 0.02em 0.1em $black) drop-shadow(1px 1px 0 $black);
    font-size: 0.85em;
  }
}

.white-link-arrow {
  & a {
    color: $white;

    &::before {
      background-image: url(../images/svg/white-arrow-breadcrumbs.svg) !important;
    }
  }
}

.--d-block {
  display: block !important;
}

.--d-none {
  display: none !important;
}

.--active-radio {
  color: $yellow !important;
  background-color: $blackFont !important;
}

.building-cards {
  .left {
    position: relative;
    right: -100%;
  }
  .right {
    position: relative;
    left: -100%;
  }
}

.--btn-sort {
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 3px;
  transition: all 0.3s ease;
  display: flex;

  &.--reverse {
    flex-direction: row-reverse;
  }
}

.--btn-sort:hover,
.--btn-sort.--active {
  color: $yellow !important;
  background-color: $blackFont !important;
}

.no-stiky {
  .sticky {
    position: static;
  }
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 3;
}

.enquire-black {
  a {
    background-color: $black;
    color: $white;
  }
}

.--swap-left {
  transform: translateX(-10px) !important;
  transition: transform 0.3s ease;
}

.--swap-right {
  position: relative;
  transform: translateX(-100vw);
  transition: transform 0.3s ease;
}

.mapboxgl-map {
  position: relative !important;
  width: 100%;
  max-width: 1920px;
  height: 600px !important;
  margin: 0 auto;
  z-index: 1;
}

.--show-items {
  & li {
    @media (max-width: $tablet) {
      display: block !important;
    }
  }
}

.--swap-search-menu-right {
  transform: translateX(100vw) !important;
  transition: transform 0.3s ease;
}

.--swap-search-input-wrap {
  z-index: 1 !important;

  &::before {
    transform: translateX(0) !important;
  }
}

.--swap-search-input-right {
  transform: translateX(0) !important;
  transition: transform 0.3s ease;
}

.--d-none {
  display: none !important;
}

.--d-none-mob {
  @media (max-width: $tablet) {
    display: none;
  }
}

.--d-flex {
  display: flex;
}

.--cards-sort-left-price, .--cards-sort-rtb {
  display: flex;

  @media (max-width: $tablet) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (min-width: $desktop) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4px;
  }

  & span {
    min-height: 24px;
    display: flex;
    align-items: center;
  }

  & > span:not(:last-child) {
    margin-right: 4px;
  }
}

.--cards-sort-rtb {
  margin-left: 40px;
}

.--sort-by-name {
  height: 24px;
  display: flex;
  align-items: center;
  @media (min-width: $desktop) {
    margin-right: 40px;
  }
}

.cards__wrap {
  @media (max-width: $tablet) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  @media (min-width: $desktop) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
  }
}

.--ml-0 {
  margin-left: 0 !important;
}

.prepare-contracts-slider {
  max-width: 1280px;
  margin: 0 auto;
  position: unset;

  & .slick-slide:nth-child(3n + 2) {
    & .prepare-contracts-slider__border {
      border-top: 8px solid $black;
    }
  }
}

.facades-available-slider {
  max-width: 1280px;
  margin: 0 auto;
  position: unset;

  // & .slick-slide:nth-child(2n - 2) {
  //   & .right {
  //     margin-right: 40px;
  //   }
  // }

  & .slick-track {
    @media (max-width: $tablet) {
      width: 100% !important;
      display: flex;
      flex-direction: column;
    }

    & .slick-slide {
      @media (max-width: $tablet) {
        width: 100% !important;
      }

      @media (min-width: $desktop) {
        width: 576px !important;
      }

      &:nth-child(2n + 1) {
        @media (min-width: $desktop) {
          margin-right: 128px;
        }
      }

      & .empty-div-for-slider {
        @media (max-width: $tablet) {
          display: none;
        }
      }
    }
  }
}

li.lightswitch > a {
  display: flex;
  justify-content: space-between;
  min-width: 176px;
}

li.lightswitch > a::after {
  display: block;
  content: '';
  width: 24px;
  height: 24px;
  background-image: url(../images/svg/arrow-up-right.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.similar-packages-slider {
  position: unset;

  .slick-slide > div {
    margin: 0 10px;
  }
  .slick-list {
    margin: 0 -10px;
  }

  .slider-button-wrap {
    @media (max-width: $tablet) {
      display: none;
    }
  }
}

.--active-region {
  color: $yellow !important;
  background-color: $blackFont !important;
}

.--active-location {
  color: $yellow !important;
}

.--only-mobile {
  @media (min-width: $tablet) {
    display: none !important;
  }
}

.map-pop-up {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.4px;
    margin-bottom: 20px;

    &-name {
      margin-right: 5px;
    }
  }

  &__button-wrap {
    display: flex;

    & a {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

.mapboxgl-popup {
  max-width: unset !important;
}

.thank-you-message {
  display: flex;
  flex-direction: column;
  padding: 96px 123px 80px 40px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.4px;

  &.--home-designe {
    padding: 0;
  }

  strong {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.9px;
  }
}

// Slide pane
.slide-pane__content {
  padding: 0;
}
.slide-pane__overlay {
  z-index: 9999999999;
}
html {
  height: -webkit-fill-available;
}
.slide-pane__overlay,
.slide-pane_from_bottom {
  height: 100% !important;
  height: 100vh !important;
  height: -webkit-fill-available !important;
  margin-top: 0 !important;
}

.slide-pane_from_right {
  height: auto !important;
}

// SVG styling
@mixin stroke($sizes: 1px, $color: #{$black}, $y: 0) {
  $size: list.nth($sizes, 1);
  $negSize: list.nth($sizes, 2) or $size * -1;

  --stroke-outer: #{$negSize} #{$negSize} 0 #{$color}, 0 #{$negSize} 0 #{$color}, #{$size} #{$negSize} 0 #{$color}, #{$size} #{$y} 0 #{$color},
    #{$size} #{$y} 0 #{$color}, 0 #{$y} 0 #{$color}, #{$negSize} #{$y} 0 #{$color}, #{$negSize} #{$y} 0 #{$color};

  --stroke-under: 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color},
    0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color},
    0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color},
    0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color},
    0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color},
    0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color},
    0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color},
    0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color},
    0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color},
    0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color},
    0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color}, 0 #{$y} #{$size} #{$color};
}

svg {
  text {
    font-family: 'Helvetica Neue LT W05_57 Cond', 'HelveticaNeueLTPro-Cn', 'Arial Narrow', $mainFont, sans-serif !important;
    font-weight: normal;
    font-style: normal;
    @include stroke(min(4px, 0.4em) max(-4px, -0.4em), $greyLighter);
    #pdfContent & {
      @include stroke(min(2px, 0.2em) max(-2px, -0.2em), $white);
    }
    background: $greyLighter;
    outline: 2px $greyLighter;
    * {
      font-family: inherit !important;
      font-weight: inherit !important;
      font-style: inherit !important;
      @include stroke(min(1.5px, 0.15em) max(-1.5px, -0.15em), $greyLighter, 1px);
      #pdfContent & {
        @include stroke(min(1px, 0.1em) max(-1px, -0.1em), $white, 1px);
      }
    }
  }
  [fill='#FFFFFF'] {
    fill: transparent;
  }
  [data-id='Options'] {
    text {
      background: $yellow;
      outline: 2px $yellow;
      &,
      #pdfContent & {
        @include stroke(min(4px, 0.4em) max(-4px, -0.4em), $yellow);
        * {
          @include stroke(min(1.5px, 0.15em) max(-1.5px, -0.15em), $yellow, 1px);
        }
      }
    }
    [fill='#8ED7F7'],
    [fill='#88D3F5'],
    [fill='#8DD5F6'],
    [fill='#FBDE05'],
    [fill='#B7E4E5'] {
      fill: $yellow;
    }
  }
}
body > #floorplan-pdf {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  // height: 100vh;
  overflow: hidden;
  z-index: 9999;
}

.slick-dots li button:before {
  font-size: 10px;
  color: #121211;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  color: #fedb00;
}

.slick-slider .house-info-img.full-height {
  height: 72vh !important;
}

.disclaimer {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;

  span {
    font-weight: 700;
  }
}

.display-home-slider + .youtube-embed {
  margin-top: 32px;

  @media (min-width: $tablet) {
    margin-top: 80px;
  }
}
