/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 10, 2020 */
@font-face {
  font-family: "Greycliff CF";
  src: url("/fonts/greycliffcf-medium-webfont.eot");
  src: url("/fonts/greycliffcf-medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/greycliffcf-medium-webfont.woff2") format("woff2"),
    url("/fonts/greycliffcf-medium-webfont.woff") format("woff"),
    url("/fonts/greycliffcf-medium-webfont.ttf") format("truetype"),
    url("/fonts/greycliffcf-medium-webfont.svg#greycliff_cfmedium")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Fonts for SVG floorplans - from Fonts.com */
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=3f4c3b21-eee9-4945-b433-ffc5e7b65f23");
@font-face {
  font-family: "Helvetica Neue LT W05_57 Cond";
  src: url("/fonts/for-svg-floorplans/9516ef74-4fe9-45fe-a09f-493cb9518f71.woff2")
      format("woff2"),
    url("/fonts/for-svg-floorplans/5b99bc59-1b2e-446b-961e-2e1dbdfcd641.woff")
      format("woff");
}
