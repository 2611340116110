@import 'styles/variables.scss';
.rich-text {
  ul,
  ol,
  li,
  p {
    margin: 0px;
    line-height: 22px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  a {
    text-decoration: underline;
    font-weight: 700;
    transition: color 0.2s ease-in-out;

    @media only screen and (min-width: $mobile) {
      transition: color 0.2s ease-in-out;
      &:hover {
        color: $lightBlack;
        text-decoration: none;
      }
    }
  }

  ul {
    list-style: disc;
  }

  ul,
  ol {
    margin-left: 20px;
  }

  h2 {
    margin-bottom: 16px;

    @media (min-width: $desktop) {
      margin-bottom: 40px;
    }
  }

  h3 {
    margin-bottom: 20px;
  }

  h4 {
    margin-bottom: 20px;
  }
}
